import { post, get ,put ,del } from "./axios.js";
let api = {

//查询轮播
findAdvertise: json => {
  return get("/client/advertise/findAdvertise", json);
},

//查询首页
findHomeData: json => {
  return get("/client/home/findHomeData", json);
},

//查询全国就业名企
findHomeNationalEmployment: json => {
  return get("/client/home/findHomeNationalEmployment", json);
},

//获取所有工种
findTypeWork: json => {
  return get("/client/work_type/findTypeWork", json);
},

//查询平台信息
findPlatformInformation: json => {
  return get("/client/platform/findPlatformInformation", json);
},
//查询课程信息
findCourseData: json => {
  return get("/client/course/findCourseData", json);
},
//查询关于我们信息
findAboutUsData: json => {
  return get("/client/about/findAboutUsData", json);
},
//查询学校文化
findSchoolCulture: json => {
  return get("/client/about/findSchoolCulture", json);
},
//查询金牌讲师信息
findTeacherData: json => {
  return get("/client/teacher/findTeacherData", json);
},
//获取学员风采信息
findStudentInfo: json => {
  return get("/client/student/findStudentInfo", json);
},
//查询公开课数据
findPublicCourse: json => {
  return get("/client/public/course/findPublicCourse", json);
},
//分页查询新闻列表信息
findPageNews: json => {
  return get("/client/news/findPageNews", json);
},
//查询新闻详情
findNewsInfo: json => {
  return get("/client/news/findNewsInfo", json);
},
//查询推荐新闻
findRecommendNews: json => {
  return get("/client/news/findRecommendNews", json);
},
//查询二级分类
findTwoTypeWork: json => {
  return get("/client/work_type/findTwoTypeWork", json);
},
//查询报名展示信息
findApplyInfo: json => {
  return get("/client/apply/info/findApplyInfo", json);
},

//添加报名信息
addCourseApply: json => {
  return post("/client/course/apply/addCourseApply", json);
},

//查询所有课程
findAllCourse: json => {
  return get("/client/course/findAllCourse", json);
},
//登录
loginApi: json => {
  return post("/client/student/login", json);
},
//获取学员课程视频
getPublicCourseVideo: json => {
  return post("/client/public/course/getPublicCourseVideo", json);
},
//查询分类
findAdmissionsCooperationType: json => {
  return get("/client/admissions/findAdmissionsCooperationType", json);
},
//分页查询招生就业对外合作列表
findPageAdmissionsCooperation: json => {
  return get("/client/admissions/findPageAdmissionsCooperation", json);
},
//查询安全课程信息
findCourseTwoData: json => {
  return get("/client/coursetwo/findCourseTwoData", json);
},
//获取注册类执业资格课程信息
findRegistrationInfo: json => {
  return get("/client/registration/qualification/course/findRegistrationInfo", json);
},
//获取施工现场专业管理人员课程信息
findConstructionManagerInfo: json => {
  return get("/client/construction/manager/findConstructionManagerInfo", json);
},
//查询建筑工程施工课程信息
findArchitectureWorksInfo: json => {
  return get("/client/architecture/works/findArchitectureWorksInfo", json);
},
//获取装饰装修类课程信息
findDecorationFurnish: json => {
  return get("/client/decoration/furnish/findDecorationFurnish", json);
},
//获取专项安全培训课程信息
findSafetyTrainingInfo: json => {
  return get("/client/safety/training/findSafetyTrainingInfo", json);
},
//获取特种作业课程详细信息
findSpecialOperationsInfo: json => {
  return get("/client/special/operations/findSpecialOperationsInfo", json);
},
//添加用户提交的测评信息
addUserQuestionInfo: json => {
  return post("/client/user/question/info/addUserQuestionInfo", json,1);
},
//新增用户领取资料
addGetInformation: json => {
  return post("/client/user/get/information/addGetInformation", json,);
},
//查询一级工种
findOneTypeWork: json => {
  return get("/client/work_type/findOneTypeWork", json,);
},
//通过二级工种获取一级工种信息
findSecondaryTypeWork: json => {
  return get("/client/work_type/findSecondaryTypeWork", json,);
},
//分页查询劳务信息
findPageHomeLaborInfo: json => {
  return get("/client/home/labor/info/findPageHomeLaborInfo", json,);
},
//查询果尔佳站群
findGejStationGroup: json => {
  return get("/client/gej-station/findGejStationGroup", json,);
},
//获取学历提升信息
findEducationPromotionCourse: json => {
  return get("/client/education-promotion/findEducationPromotionCourse", json,);
},

//改版第四版接口
//获取个人服务企业服务下拉树列表
menuTreeselect: json => {
  return get("/client/person/company/menu/treeselect", json,);
},
//获取职称申报详细信息
professionalApi: json => {
  return get("/client/job/salutation/declare/course/getInfo", json,);
},
//获取其他培训详细信息
otherTrainingApi: json => {
  return get("/client/other/training/course/getInfo", json,);
},
//获取深户办理详细信息
householdAccountApi: json => {
  return get("/client/deep/household/course/getInfo", json,);
},
//获取职业技能等级认定详细信息
vocationalSkillLevelApi: json => {
  return get("/client/vocational/skill/level/course/getInfo", json,);
},
//分页获取劳务信息
serviceInfoApi: json => {
  return get("/client/labour/service/information/course/findPageLabourServiceInformation", json,);
},
//获取高新企业认定申报详细信息
highTechEnterpriseApi: json => {
  return get("/client/high/salary/company/identification/course/getInfo", json,);
},
//获取建筑工程资质申报详细信息
constructionProjectApi: json => {
  return get("/client/construction/project/declare/course/getInfo", json,);
},
//获取建筑工程资质申报详细信息
vocationalSkillApi: json => {
  return get("/client/skill/rating/agency/course/getInfo", json,);
},
//获取安全员ABC证详细信息
safetyOfficerApi: json => {
  return get("/client/safety/officer/abc/course/getInfo", json,);
},
//获取产业工人培训详细信息
workerTrainingApi: json => {
  return get("/client/industrial/worker/training/course/getInfo", json,);
},
//获取体系认证详细信息
systemCertificationApi: json => {
  return get("/client/system/certification/course/getInfo", json,);
},
//获取企业补贴申报详细信息
enterpriseSubsidyApi: json => {
  return get("/client/enterprise/subsidy/course/getInfo", json,);
},
//获取场地租赁详细信息
siteLeaseApi: json => {
  return get("/client/site/lease/course/getInfo", json,);
},
//获取场地租赁详细信息
laborDispatchApi: json => {
  return get("/client/labor/dispatch/course/getInfo", json,);
},
//获取证书查询详细信息
certificateQueryPageApi: json => {
  return get("/client/certificate/query/course/getInfo", json,);
},
//获取果尔佳产业工人证书详细信息
certificateQueryApi: json => {
  return get("/client/certificate/query/course/getCertificateInfo", json,);
},
//获取新版首页信息详细信息
newHomeApi: json => {
  return get("/client/new/home/getInfo", json,);
},
//公务员考试
civilServantApi: json => {
    return get("/client/civil/servant/exam/course/getInfo", json,);
},
//工业自动化人才培训
automatedPersonnelApi: json => {
    return get("/client/automated/personnel/training/course/getInfo", json,);
},
};


export default api;
