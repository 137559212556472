<template>
    <div id="app">
        <div class="app_main">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
    </div>
</template>
<script>
import WARBLER from '@/http/yingyu'
export default {
    data() {
        return {}
    },
    created() {
        // this.$nextTick(() => {
        // 	// 禁用右键
        // 	document.oncontextmenu = new Function('event.returnValue=false')
        // 	// 禁用选择
        // 	document.onselectstart = new Function('event.returnValue=false')
        // })
        // document.addEventListener('keydown', function (e) {
        // 	if (e.key == 'F12') {
        // 		e.preventDefault() // 如果按下键F12,阻止事件
        // 	}
        // })
    },
    mounted() {
        this.init()
        // document.body.style.filter = 'grayscale(100%)'
    },
    methods: {
        init() {
            WARBLER.init({
                WARBLER_URL: 'https://www.yingyuchat.com',
                WARBLER_KEFU_ID: 'golkia2022',
                WARBLER_ENT: '1090',
                WARBLERLANG: 'cn', //前台界面语言,cn/en/tc,cn代表中文界面,en代表英文,tc代表繁体,默认是中文
                WARBLERAUTO_SHOW: true, //对话框默认一开始就显示,false则为不显示
            })
        },
    },
}
</script>
<style lang="scss">
body,
html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#app {
    width: 100%;
    height: 100%;
}

.app_main {
    min-height: 1000px;
}

.launchButtonBox {
    height: 110px;
    top: 50% !important;
    right: 0 !important;
    background: #013c7e;
    display: none !important;
}

.launchButtonBox li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.launchButtonBox li:nth-child(1) {
    border-bottom: 1px solid #fff;
}

.layui-kflayer {
    left: var(--sidemargin) !important;
    right: 0px !important;
    z-index: 999999;
}
</style>