import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.css"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import api from "./http/api";
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
var option = {
    maxSpreadZoom: 1, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
    fullscreenEl: false, //控制是否显示右上角全屏按钮
    closeEl: false, //控制是否显示右上角关闭按钮
    tapToClose: true, //点击滑动区域应关闭图库
    shareEl: false, //控制是否显示分享按钮
    zoomEl: false, //控制是否显示放大缩小按钮
    counterEl: false, //控制是否显示左上角图片数量按钮
    arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
    tapToToggleControls: true, //点击应切换控件的可见性
    clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, option)
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.api = api;
//前置守卫
router.beforeEach((to, from, next) => {
     setTimeout(() => {
         if (to.meta.content) {
             let head = document.getElementsByTagName('head');
             let meta = document.createElement('meta');
             document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
             document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
             meta.content = to.meta.content;
             head[0].appendChild(meta)
         }
         if (to.meta.title) {
           
              document.title = to.meta.title+'—果尔佳职业技能培训学校'
         }
         
        }, 500)
        next()
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
