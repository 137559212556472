import axios from "axios";
import store from "../store";
import router from "../router";
import qs from "qs";
import { Message } from 'element-ui'

axios.defaults.timeout = 30000;
// axios.defaults.baseURL = "http://192.168.1.125:8880/"; //本地-向润
axios.defaults.baseURL = "https://www.golkia-school.com/"; //正式  

//请求拦截器
axios.interceptors.request.use(
  config => {
    if (store.state.userData != null) {
      if (store.state.userData.token == undefined) {
        store.state.userData.token = "";
      }
      config.headers["token"] = store.state.userData.token;
    } else {
    }
    return config;
  },
  () => {
    return null;
  }
);

//响应拦截器  
axios.interceptors.response.use(function(response) {
   if (  response.data.code == 10408 || response.data.code == 10104) {
    sessionStorage.clear();
    localStorage.clear();
     Message({
            message: response.data.msg,
            type: 'error'
        })
    router.push({
      path: "/login",
      query: {
        isLogin: 'no'
      }
    });
  }else if(response.data.code == 500){
        sessionStorage.clear();
        localStorage.clear();
        if(response.data.msg == 'token不能为空' ){
            router.push({
                path: "/login",
                query: {
                    isLogin: 'no'
                }
            });
        }else{
            return Message({
                    message: response.data.msg,
                    type: 'error'
            })
        }
   } else if (response.data.code != 0 && response.data.code != 200){
        Message({
            message: response.data.msg,
            type: 'error'
        })
   
  }
  return response;
});

// export const imgUrl = "http://192.168.1.189:8880/"; // 图片地址

export function post(url, params, type) {
  return new Promise((resolve, reject) => {
    if (type == 1) {
      axios.defaults.headers.post["Content-Type"] = "application/json";
    } else {
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      params = qs.stringify(params);
    }
    axios
      .post(url, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
     axios.defaults.headers.put["Content-Type"] =
        "application/x-www-form-urlencoded";
      params = qs.stringify(params);
    axios
      .put(url, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function del(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        params: params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
