import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
    const state = {
        workData:[],
        workDataList:[
            {   
                id:1,
                name:'特种作业',
                url:"/homes/specialOperations"
            },
            {   
                id:2,
                name:'职业资格',
                url:"/homes/registered"
            },
            {   
                id:3,
                name:'技能培训',
                url:"/homes/constructionSite",
                children:[
                    {   
                        id:11,
                        name:'施工现场专业人员',
                        url:"/homes/constructionSite"
                    },
                    {   
                        id:22,
                        name:'建筑工程施工类',
                        url:"/homes/constructionEngineering"
                    },
                    {   
                        id:33,
                        name:'装饰装修类',
                        url:"/homes/adornmentDecorated"
                    },
                ]
            },
            {   
                id:4,
                name:'安全培训',
                url:"/homes/safetyTraining"
            },
            {   
                id:5,
                name:'学历提升',
                url:"/homes/educationBg"
            },
        ],
        timeData:1000,
        Information :{},
        personData:[],
        companyData: [],
    };
     //个人服务分类
    if(
        sessionStorage.getItem("personData") != null &&
        sessionStorage.getItem("personData") != undefined
    ){
        state.personData = JSON.parse(sessionStorage.getItem("personData"));
    }
     //企业服务分类
    if(
        sessionStorage.getItem("companyData") != null &&
        sessionStorage.getItem("companyData") != undefined
    ){
        state.companyData = JSON.parse(sessionStorage.getItem("companyData"));
    }
    //工种
    if(
        sessionStorage.getItem("workData") != null &&
        sessionStorage.getItem("workData") != undefined
    ){
        state.workData = JSON.parse(sessionStorage.getItem("workData"));
    }
    if(
        sessionStorage.getItem("Information") != null &&
        sessionStorage.getItem("Information") != undefined
    ){
        state.Information = JSON.parse(sessionStorage.getItem("Information"));
    }
    const mutations ={
        ///工种
        getWorkData(state,data){
            state.workData = data
            sessionStorage.setItem("workData",JSON.stringify(data)) 
        },
        getInfo(state,data){
            state.Information = data
            sessionStorage.setItem("Information",JSON.stringify(data)) 
        },
        gettimeData(state,data){
            state.timeData = data
        },
        //个人服务分类
        getPersonData(state,data){
            state.personData = data
        },
        //企业服务分类
        getCompanyData(state,data){
            state.companyData = data
        },
        
    };
    const getters = {};
    const modules = {};
  export default new Vuex.Store({
    state,
    mutations,
    getters,
    modules,
});