import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
     {
    path: "/",
    // path: "*",
    name: "home",
    redirect: "/homes/index",
    component: resolve => require(["@/views/Home"], resolve),
    children: [
      {
        path: "/homes/index",
        name: "home/index",
        component: resolve => require(["@/views/homes/index"], resolve),
        meta: {
            title: "首页" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '果尔佳职业技能培训学校，2020年11月创立于广东省深圳市，学校培训项目：电工证，焊工证、高空证和特种工种的理论与实操培训课程：注册类执业资格、施工现场专业管理人员、建筑工程施工类、装饰装修类、特种作业类、专项安全培训等。'
			}
        }
      },
      {
        path: "/homes/course",
        name: "/course",
        component: resolve => require(["@/views/homes/course"], resolve),
        meta: {
          title: "热门课程" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/aboutUs",
        name: "aboutUs",
        component: resolve => require(["@/views/homes/aboutUs"], resolve),
        meta: {
            title: "关于我们" ,
            keepAlive: true,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/lecturer",
        name: "/lecturer",
        component: resolve => require(["@/views/homes/lecturer"], resolve),
        meta: {
          title: "金牌讲师" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/student",
        name: "/student",
        component: resolve => require(["@/views/homes/student"], resolve),
        meta: {
          title: "学院风采" 
        }
      },
      {
        path: "/homes/publicClass",
        name: "/publicClass",
        component: resolve => require(["@/views/homes/publicClass"], resolve),
        meta: {
          title: "公开课" 
        }
      },
      {
        path: "/homes/newsCenter",
        name: "newsCenter",
        component: resolve => require(["@/views/homes/newsCenter"], resolve),
        meta: {
            title: "新闻资讯" ,
            keepAlive: true,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/newsCenterDetails",
        name: "newsCenterDetails",
        component: resolve => require(["@/views/homes/newsCenterDetails"], resolve),
        meta: {
          title: "新闻中心详情" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/signUp",
        name: "/signUp",
        component: resolve => require(["@/views/homes/signUp"], resolve),
        meta: {
          title: "立即报名" 
        }
      },
      {
        path: "/homes/contactUs",
        name: "/contactUs",
        component: resolve => require(["@/views/homes/contactUs"], resolve),
        meta: {
          title: "联系我们" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      }, 
      {
        path: "/homes/login",
        name: "/login",
        component: resolve => require(["@/views/homes/login"], resolve),
        meta: {
          title: "登录" 
        }
      },
      {
        path: "/homes/recruitStudent",
        name: "/recruitStudent",
        component: resolve => require(["@/views/homes/recruitStudent"], resolve),
        meta: {
          title: "招生就业" 
        }
      },
      {
        path: "/homes/safety",
        name: "/safety",
        component: resolve => require(["@/views/homes/safety"], resolve),
        meta: {
          title: "专项安全培训" 
        }
      }, 
      {
        path: "/homes/registered",
        name: "/registered",
        component: resolve => require(["@/views/homes/registered"], resolve),
        meta: {
            title: "注册类职业资格," ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/constructionSite",
        name: "/constructionSite",
        component: resolve => require(["@/views/homes/constructionSite"], resolve),
        meta: {
          title: "施工现场专业人员" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/constructionEngineering",
        name: "/constructionEngineering",
        component: resolve => require(["@/views/homes/constructionEngineering"], resolve),
        meta: {
          title: "建筑工程施工类" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/adornmentDecorated",
        name: "/adornmentDecorated",
        component: resolve => require(["@/views/homes/adornmentDecorated"], resolve),
        meta: {
          title: "装饰装修类" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/safetyTraining",
        name: "/safetyTraining",
        component: resolve => require(["@/views/homes/safetyTraining"], resolve),
        meta: {
          title: "专项安全培训" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类链',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
       {
        path: "/homes/specialOperations",
        name: "/specialOperations",
        component: resolve => require(["@/views/homes/specialOperations"], resolve),
        meta: {
          title: "特种作业类" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
    {
        path: "/homes/educationBg",
        name: "/educationBg",
        component: resolve => require(["@/views/homes/educationBg"], resolve),
        meta: {
          title: "学历提升" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/professional",
        name: "/professional",
        component: resolve => require(["@/views/homes/professional"], resolve),
        meta: {
          title: "职称申报" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/applyHouseholdAccount",
        name: "/applyHouseholdAccount",
        component: resolve => require(["@/views/homes/applyHouseholdAccount"], resolve),
        meta: {
          title: "深户办理" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/vocationalSkillLevel",
        name: "/vocationalSkillLevel",
        component: resolve => require(["@/views/homes/vocationalSkillLevel"], resolve),
        meta: {
          title: "职业技能等级认定" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/serviceInfo",
        name: "/serviceInfo",
        component: resolve => require(["@/views/homes/serviceInfo"], resolve),
        meta: {
          title: "劳务信息" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/otherTraining",
        name: "/otherTraining",
        component: resolve => require(["@/views/homes/otherTraining"], resolve),
        meta: {
          title: "其他培训" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/highTechEnterprise",
        name: "/highTechEnterprise",
        component: resolve => require(["@/views/homes/enterpriseService/highTechEnterprise"], resolve),
        meta: {
          title: "高新企业认定申报" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
       {
        path: "/homes/enterpriseService/constructionProject",
        name: "/constructionProject",
        component: resolve => require(["@/views/homes/enterpriseService/constructionProject"], resolve),
        meta: {
          title: "建筑工程资质申报" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/vocationalSkill",
        name: "/vocationalSkill",
        component: resolve => require(["@/views/homes/enterpriseService/vocationalSkill"], resolve),
        meta: {
          title: "职业技能等级评价机构申报" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/safetyOfficer",
        name: "/safetyOfficer",
        component: resolve => require(["@/views/homes/enterpriseService/safetyOfficer"], resolve),
        meta: {
          title: "安全员ABC证" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/laborDispatch",
        name: "/laborDispatch",
        component: resolve => require(["@/views/homes/enterpriseService/laborDispatch"], resolve),
        meta: {
          title: "劳务派遣" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
    {
        path: "/homes/certificateQueryPage",
        name: "/certificateQueryPage",
        component: resolve => require(["@/views/homes/certificateQueryPage"], resolve),
        meta: {
          title: "证书查询" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/certificateQuery",
        name: "/certificateQuery",
        component: resolve => require(["@/views/homes/certificateQuery"], resolve),
        meta: {
          title: "证书查询" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/systemCertification",
        name: "/systemCertification",
        component: resolve => require(["@/views/homes/enterpriseService/systemCertification"], resolve),
        meta: {
          title: "企业服务体系认证" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/enterpriseSubsidy",
        name: "/enterpriseSubsidy",
        component: resolve => require(["@/views/homes/enterpriseService/enterpriseSubsidy"], resolve),
        meta: {
          title: "企业补贴申报" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
      {
        path: "/homes/enterpriseService/siteLease",
        name: "/siteLease",
        component: resolve => require(["@/views/homes/enterpriseService/siteLease"], resolve),
        meta: {
          title: "场地租赁" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
      },
    {
        path: "/homes/enterpriseService/workerTraining",
        name: "/workerTraining",
        component: resolve => require(["@/views/homes/enterpriseService/workerTraining"], resolve),
        meta: {
          title: "产业工人培训" ,
            content: {
				keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
				description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
			}
        }
    },
    {
        path: "/homes/civilServant",
        name: "/civilServant",
        component: resolve => require(["@/views/homes/civilServant"], resolve),
        meta: {
            title: "公务员考试",
            content: {
                keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
                description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
            }
        }
    },
    {
        path: "/homes/talentTraining",
        name: "/talentTraining",
        component: resolve => require(["@/views/homes/talentTraining"], resolve),
        meta: {
            title: "工业自动化人才培训",
            content: {
                keywords: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类',
                description: '注册类职业资格,施工现场专业人员,建筑工程施工类,装饰装修类,专项安全培训,特种作业类'
            }
        }
    },
      
    ]},
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   },
]

const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
  routes
})

export default router
